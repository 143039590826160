export const findLastIndex = (arr, predicate) => {
	const reversedArray = [...arr].reverse()
	const index = reversedArray.findIndex(predicate)

	if (index === -1) {
		return -1
	}

	return arr.length - 1 - index
}
export const debounce = (func, wait) => {
	let timeout
	return function (...args) {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			func.apply(this, args)
		}, wait)
	}
}
