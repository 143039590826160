<template>
	<article
		:class="[
			'single-product wrap-lg-up',
			currentItem.itemRaw.slug,
			category && category.slug,
			hasGroupedVariations && 'variation-product',
			isDemiColour && 'is-demi-colour',
			currentItemImages.length > 1 && 'has-gallery',
		]"
		role="main"
	>
		<div class="product--heading padding-x-wrap">
			<h1 class="section-title product--title">{{ productTitle }}</h1>
			<div class="product--subheading">
				<price-info :item="currentItem" :sale-information="saleInformation" />
				<p v-if="isWholesaleCustomer && isApparel" class="product--commission">No commission</p>
				<p v-else-if="isWholesaleCustomer" class="product--commission">
					Retail: <market-currency :amount="retailAmount" display-without-zeros />
				</p>
			</div>
		</div>

		<div
			:class="['product--gallery', shouldRenderGalleryAsSlider && 'keen-slider']"
			id="product-gallery"
			ref="gallerySliderRef"
		>
			<figure
				:class="shouldRenderGalleryAsSlider && 'keen-slider__slide'"
				v-for="image in currentItemImages"
				:key="`${currentItem.itemCode}${image.url}`"
				role="figure"
				:aria-label="image.filename.replace(/-/g, ' ')"
			>
				<video v-if="image.mime_type.startsWith('video')" :src="image.url" autoplay loop muted playsinline />
				<img v-else :src="image.url" :alt="image.filename.replace(/-/g, ' ')" loading="eager" rel="preload" />
			</figure>
			<label-tag :item="currentItem" :sale-information="saleInformation" />
			<div v-if="shouldRenderGalleryAsSlider" :class="['dots', !isApparel && 'on-light-bg']">
				<button
					v-for="(image, index) in currentItemImages"
					@click="gallerySlider.moveToSlideRelative(index)"
					:class="['dot', currentSlide === index && 'active']"
					:key="`${currentItem.itemCode}${image.url}`"
					aria-label="Pagination Dot"
				></button>
			</div>
		</div>
		<div
			v-if="isDemiColour"
			:data-variation-dropdown-state="variationDropdownIsActive ? 'open' : 'closed'"
			class="variation-dropdown--wrapper wrap-md-down"
		>
			<div class="label-and-colour-wheel">
				<p class="title-text">Color</p>
				<!-- <span @click="showColourWheel" class="cursor-pointer text-underline">Corrector Wheel</span> -->
			</div>
			<div @click="variationDropdownIsActive = !variationDropdownIsActive" class="variation-dropdown-select">
				<div>
					<span class="variant-swatch" :style="{ 'background-color': hexValue(currentItem) }"></span>
					<span>{{ currentItem.shortTitle }}</span>
				</div>
				<span><svg-vue icon="chevron-down"></svg-vue></span>
			</div>
			<div class="variation-dropdown-groups">
				<div
					v-for="(itemsGroup, i) in groupedVariations.items"
					:key="groupedVariations.names[i]"
					class="variation-dropdown-group"
				>
					<p class="title-text">{{ groupedVariations.names[i] }}</p>
					<div class="variant-swatches--container">
						<router-link
							v-for="item in itemsGroup"
							:key="item.itemCode"
							@click.prevent.native="handleSelectItem(item)"
							replace
							:to="{
								name: routeName,
								params: {
									languageCode: languageCode,
									categoryId: category.WebCategoryID,
									categorySlug: category.slug,
									itemId: item.itemId,
									itemSlug: item.itemRaw.slug,
								},
							}"
							:title="`${item.shortTitle}${item.outOfStock ? ' (Out of stock)' : ''}`"
							:class="['variant-swatch--wrapper', item === currentItem && 'active']"
						>
							<div class="variant-swatch--outer">
								<div
									:class="[
										'variant-swatch',
										item.outOfStock && 'is-oos',
										hasLightHexValue(item) && 'variant-swatch--outline',
									]"
									:style="{ 'background-color': hexValue(item) }"
								>
									<span :class="['variant-name', item.itemRaw.slug]">
										{{ item.shortTitle }}
									</span>
								</div>
							</div>
							<span class="visually-hidden">{{ item.shortTitle }}</span>
							<p class="label-text">{{ item.shortTitle }}</p>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			:class="['product--grouped-variants-nav', hasGroupedVariations && 'tab-labels-wrapper padding-x-wrap']"
			role="tablist"
		>
			<div v-if="hasGroupedVariations">
				<div>
					<router-link
						replace
						:to="{
							name: routeName,
							params: {
								languageCode: 'en',
								categoryId: category.WebCategoryID,
								categorySlug: category.slug,
								itemId: groupedVariations.items[index][0].itemId,
								itemSlug: groupedVariations.items[index][0].itemRaw.slug,
							},
						}"
						role="tab"
						v-for="(groupName, index) in groupedVariations.names"
						:key="groupName"
						@click.prevent.native="handleSelectGroupedItems(index)"
						:class="['title-text tab-label', index === groupedItemsIndex && 'active']"
					>
						{{ groupName }}
					</router-link>
				</div>
			</div>
		</div>
		<div v-if="!isDemiColour && hasVariations" class="wrap product--variant-swatches">
			<p v-if="itemsArray.length > 1 || hasGroupedVariations" class="title-text product--variant-label">
				{{ currentItem.variantLabel }}:
				<span>{{ currentItem.shortTitle || currentItem.title }}</span>
			</p>
			<div v-if="isApparel">
				<div class="label-and-sizing-guide">
					<p class="title-text">{{ currentItem.variantLabel }}</p>
					<span @click="showSizeGuide(isApparelBottoms)" class="cursor-pointer text-underline"> Size Guide </span>
				</div>
			</div>
			<div v-if="isGettingStartedCollection">
				<div class="label-and-collection-level-guide">
					<p class="title-text">{{ currentItem.variantLabel }}</p>
					<span @click="showCollectionLevelsModal = true" class="cursor-pointer text-underline">
						Find Your Shades
					</span>
				</div>
			</div>
			<div v-if="itemsArray.length > 1 || hasGroupedVariations" class="variant-swatches--container">
				<router-link
					replace
					:to="{
						name: routeName,
						params: {
							languageCode: languageCode,
							categoryId: category.WebCategoryID,
							categorySlug: category.slug,
							itemId: item.itemId,
							itemSlug: item.itemRaw.slug,
						},
					}"
					:key="item.itemCode"
					v-for="item in itemsArray"
					@click.prevent.native="handleSelectItem(item)"
					:title="
						item.outOfStock
							? `${item.shortTitle} (Out of stock)` || `${item.title} (Out of stock)`
							: item.shortTitle || item.title
					"
					:class="['variant-swatch--wrapper', item === currentItem && 'active']"
				>
					<div class="variant-swatch--outer">
						<div
							:class="[
								'variant-swatch',
								item.outOfStock && 'is-oos',
								hasLightHexValue(item) && 'variant-swatch--outline',
							]"
							:style="{ 'background-color': hexValue(item), 'background-image': swatchBgImgUrl(item) }"
						>
							<span :class="['variant-name', item.itemRaw.slug]">
								{{ item.shortTitle || item.title }}
							</span>
						</div>
					</div>
					<span class="visually-hidden">
						{{ item.shortTitle || item.title }}
					</span>
					<p v-if="item.isNewRelease && !isNewVariationProduct" class="label-text">New</p>
				</router-link>
			</div>
		</div>
		<div class="product--qty-add-wrapper padding-x-wrap">
			<p v-if="isEnrollContext" class="product--enrollment-mode">You cannot add items to your bag while enrolling.</p>
			<div v-if="itemNote" class="product--note" v-html="itemNote"></div>
			<div class="product--qty-add">
				<cart-item-form :key="currentItem.itemId" :item="currentItem" :sale-information="getSaleInformation" />
				<pay-later-message v-if="itemPrice > 30" :amount="itemPrice" />
				<button v-if="isLoggedIn" type="button" class="btn-save-to-list" @click="saveToList" aria-label="Save to List">
					<svg-vue icon="bookmark" />Save to List
				</button>
				<div v-if="currentItem.available || hasVariations" class="free-shipping-returns-note">
					<div class="inline-block">
						<svg-vue icon="truck-in-motion" />
						<span @click="showShippingModal" class="cursor-pointer text-underline">
							Free shipping on orders over {{ countryCode === 'CA' ? 'C$90' : '$50' }}
						</span>
					</div>
					<div class="inline-block">
						<svg-vue icon="box-package" />
						<span @click="showReturnPolicy" class="cursor-pointer text-underline"> Easy returns &amp; exchanges </span>
					</div>
				</div>
			</div>
		</div>
		<aside class="product--details" v-if="productInfoFields.length === 1">
			<p class="title-text product--info-label padding-x-wrap">
				{{ productInfoFields[0].title }}
			</p>
			<div class="p-details--content padding-x-wrap" v-html="productInfoFields[0].body"></div>
		</aside>
		<aside class="product--details" v-else-if="productInfoFields.length > 1">
			<div class="tab-labels-wrapper" role="tablist" :style="`--num-tabs:${productInfoFields.length};`">
				<button
					v-for="(infoField, index) in productInfoFields"
					:class="['title-text tab-label', activeInfoTab === index ? 'active' : null]"
					role="tab"
					:key="infoField.title"
					@click="handleActiveInfoTab(index)"
					aria-label="Select Tab"
				>
					{{ infoField.title }}
				</button>
			</div>
			<div
				v-for="(infoField, index) in productInfoFields"
				data-tab-content
				:class="['p-details--tab-content padding-x-wrap', activeInfoTab === index ? 'active' : null]"
				:key="`${index}-infoField`"
				v-html="infoField.body"
			/>
		</aside>
		<collection-levels-modal
			v-if="isGettingStartedCollection"
			v-show="showCollectionLevelsModal"
			@select-item-from-modal="handleSelectCollectionLevel($event)"
			:items="itemsArray"
		>
		</collection-levels-modal>
		<generic-modal ref="modal" title="Add to List">
			<template v-slot:content>
				<product-list-choice
					:item="currentItem"
					@completed="handleAddToListsComplete"
					@create-new-start="handleHideParent"
					@create-new-end="handleShowParent"
				/>
			</template>
		</generic-modal>
	</article>
</template>

<script>
import { mapState } from 'pinia'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import '@sweetalert2/theme-minimal/minimal.css'
import KeenSlider from 'keen-slider'

import { debounce } from '@components/utils/helpers_v2'
import { useAppStore, useRewardsStore, useSessionStore } from '@stores'
import { shortReturnPolicy, sizeGuide, shippingModal, isBright } from '@/js/components/utils/helpers'
import CollectionLevelsModal from '@/js/components/ProductLayout/CollectionLevelsModal.vue'

export default {
	components: {
		CollectionLevelsModal,
	},
	props: {
		item: {
			type: Object,
		},
		hasVariations: {
			default: false,
		},
		category: {
			type: Object,
		},
		categoryDescription: {
			type: String,
		},
		items: {
			type: Array,
		},
		saleInformation: {
			type: Array,
		},
	},
	data() {
		return {
			groupedItemsIndex: 0,
			itemIndex: 0,
			activeInfoTab: 0,
			variationDropdownIsActive: false,
			currentSlide: 0,
			gallerySlider: null,
			showCollectionLevelsModal: false,
		}
	},
	created() {
		if (this.routeResolvesToItem && this.routeDoesNotMatchCurrentItem) {
			this.setActiveGroupVariant()
		}
	},

	mounted() {
		this.handleOgUpdate(this.currentItem)
	},

	beforeDestroy() {
		if (this.gallerySlider) this.gallerySlider.destroy()
	},

	computed: {
		...mapState(useAppStore, ['isLargeMediaQuery']),
		...mapState(useRewardsStore, ['rewards', 'hasRewardDiscounts', 'remainingDiscountItemCount']),
		...mapState(useSessionStore, [
			'countryCode',
			'languageCode',
			'shoppingContext',
			'isWholesaleCustomer',
			'isLoggedIn',
			'isEnrollContext',
		]),
		itemPrice() {
			const { priceRaw } = this.currentItem
			const saleArr = this.saleInformation ?? []
			const itemSale = saleArr.find(
				(sale) => sale.qualifying_skus.includes(this.currentItem.itemCode) && sale.can_purchase
			)
			if (itemSale) {
				const { credit, type } = itemSale
				let discountAmount
				if (type === 'percent') {
					discountAmount = (priceRaw * credit) / 100
				} else {
					discountAmount = credit
				}
				return Math.max(0, priceRaw - discountAmount)
			}
			return priceRaw
		},
		routeName() {
			return `${this.shoppingContext}-product-layout`
		},
		isDemiColour() {
			return Number(this.$route.params.categoryId) === 184 || Number(this.$route.params.categoryId) === 356
		},
		isApparel() {
			return (
				this.currentItem.variantLabel?.toLowerCase() === 'size' &&
				!this.currentItem.title?.toLowerCase().includes('otr')
			)
		},
		isApparelBottoms() {
			return (
				this.isApparel &&
				(this.currentItem.title?.toLowerCase().includes('pants') ||
					this.currentItem.title?.toLowerCase().includes('shorts'))
			)
		},
		isGettingStartedCollection() {
			return [275, 276, 277, 278, 279, 280, 533, 534, 535, 536].includes(Number(this.$route.params.categoryId))
		},
		getSaleInformation() {
			const sale = this.saleInformation ?? []
			return sale.find((sale) => sale.qualifying_skus.includes(this.currentItem.itemCode) && sale.can_purchase)
		},
		shouldRenderGalleryAsSlider() {
			return this.currentItemImages.length > 1 && !this.isLargeMediaQuery
		},
		productTitle() {
			return this.hasVariations
				? this.allItems.length > 1
					? this.category.WebCategoryDescription
					: this.currentItem.title
				: this.currentItem.title
		},
		groupedVariations() {
			const groupHashMap = {}
			if (this.items) {
				for (const item of this.items) {
					if (item.variantGroup) {
						if (groupHashMap[item.variantGroup] === undefined) {
							groupHashMap[item.variantGroup] = []
						}
						groupHashMap[item.variantGroup].push(item)
					}
				}
				const sortedGroupHashMap = Object.entries(groupHashMap).sort((a, b) => {
					if (this.isDemiColour) {
						if (a[0].toLowerCase() === 'level 1') {
							return -1
						}
						if (b[0].toLowerCase() === 'level 1') {
							return 1
						}
						if (a[0].toLowerCase() === 'level 3') {
							return -1
						}
						if (b[0].toLowerCase() === 'level 3') {
							return 1
						}
						if (a[0].toLowerCase() === 'level 5') {
							return -1
						}
						if (b[0].toLowerCase() === 'level 5') {
							return 1
						}
						if (a[0].toLowerCase() === 'level 7') {
							return -1
						}
						if (b[0].toLowerCase() === 'level 7') {
							return 1
						}
						if (a[0].toLowerCase() === 'neutral -1') {
							return -1
						}
						if (b[0].toLowerCase() === 'neutral -1') {
							return 1
						}
						if (a[0].toLowerCase() === 'neutral') {
							return -1
						}
						if (b[0].toLowerCase() === 'neutral') {
							return 1
						}
						if (a[0].toLowerCase() === 'neutral +1') {
							return -1
						}
						if (b[0].toLowerCase() === 'neutral +1') {
							return 1
						}
					}
					// sort groups descending by quantity of items but ensure the "Classic" variant group is sorted to be first
					// (if the "Classic" group exists)
					if (a[0].toLowerCase() === 'classic') {
						return -1
					}
					if (b[0].toLowerCase() === 'classic') {
						return 1
					}
					return b[1].length - a[1].length
				})
				const names = sortedGroupHashMap.map((x) => x[0])
				const items = sortedGroupHashMap.map((x) => x[1])
				return { names, items }
			}
			return { names: [], items: [] }
		},
		hasGroupedVariations() {
			return this.groupedVariations.items.length > 0
		},
		isNewVariationProduct() {
			return this.itemsArray?.length > 1 && this.itemsArray.every((item) => item.isNewRelease)
		},
		itemsArray() {
			return this.groupedVariations.items.length > 0
				? this.groupedVariations.items[this.groupedItemsIndex]
				: this.items
				? this.items
				: null
		},
		allItems() {
			if (this.groupedVariations.items.length > 1) {
				return this.groupedVariations.items.flat()
			}
			if (this.items) {
				return this.items
			}
			if (this.item) {
				return [this.item]
			}
		},
		currentItem() {
			return this.item ? this.item : this.itemsArray[this.itemIndex]
		},
		currentItemId() {
			return this.currentItem.itemId
		},
		currentItemImages() {
			return this.currentItem.images.length > 0
				? this.currentItem.images
				: [
						{
							url: this.currentItem.productImage,
							filename: this.currentItem.productImage.split('/').reverse()[0].split('.')[0],
							mime_type: 'image/jpeg',
						},
				  ] // coerce legacy image into a sparse media object structure
		},
		routeItemId() {
			return Number(this.$route.params.itemId)
		},
		currentItemSlug() {
			return this.currentItem.itemRaw.slug
		},
		routeItemSlug() {
			return this.$route.params.itemSlug
		},
		routeResolvesToItem() {
			return Boolean(
				this.allItems?.some((item) => item.itemId === this.routeItemId && item.itemRaw.slug === this.routeItemSlug)
			)
		},
		routeDoesNotMatchCurrentItem() {
			return this.currentItemId !== this.routeItemId && this.currentItemSlug !== this.routeItemSlug
		},
		retailAmount() {
			let sv = this.currentItem.salesVolume
			let price = this.currentItem.priceRaw

			if (this.getSaleInformation) {
				const { credit, type } = this.getSaleInformation
				if (type === 'dollar') {
					sv = (sv - credit) * 0.8
				} else {
					const salePercentage = credit / 100
					sv = sv * (1 - salePercentage)
				}
			}
			// for use with wholesale only
			let retailPrice = price / 0.75
			retailPrice = retailPrice.toFixed(2)
			// % 1 === 0 used to drop decimals for display purpose if .00
			sv = sv % 1 === 0 ? sv.toFixed(0) : sv.toFixed(2) // not showing dis
			// parseInt().toString() will remove trailing zero if applicable
			return retailPrice
			// return `Retail: ${parseFloat(retailPrice).toString()}`
		},
		productInfoFields() {
			const fields = []
			if (this.currentItem.description || this.categoryDescription) {
				// return only one description, either item.description or categoryDescription - prioritize item.description
				// if the description contains markup, just return it—otherwise wrap it in a paragraph tag
				fields.push({
					title: 'Product Info',
					body: this.isDemiColour
						? this.formatForMarkup(this.categoryDescription)
						: this.currentItem.description
						? this.formatForMarkup(this.currentItem.description)
						: this.categoryDescription
						? this.formatForMarkup(this.categoryDescription)
						: this.formatForMarkup(''),
				})
			}
			if (this.currentItem.ingredients) {
				let ingredientEls = this.currentItem.ingredients
				if (!this.currentItem.ingredients.includes('<ul>')) {
					ingredientEls = ''
					const ingredientsArray = this.currentItem.ingredients.split(',')
					for (const item of ingredientsArray) {
						const ingredient = item
							.replace(/^\s+|\s+$/g, '')
							.replace('<p>', '')
							.replace('</p>', '')
						ingredientEls += `<li>${ingredient}</li>`
					}
					ingredientEls = `<ul>${ingredientEls}</ul>`
				}
				fields.push({
					title: 'Ingredients',
					// body: this.currentItem.ingredients,
					body: ingredientEls,
				})
			}
			return fields
		},
		itemNote() {
			return this.currentItem.note ? this.formatForMarkup(this.currentItem.note) : null
		},
	},
	methods: {
		setActiveGroupVariant({ item = null } = {}) {
			const { names, items } = this.groupedVariations
			const id = item?.itemId ?? this.routeItemId
			if (this.hasGroupedVariations) {
				for (const [i, itemsGroup] of Object.entries(items)) {
					const foundIndex = itemsGroup.findIndex(({ itemId }) => itemId === id)
					if (foundIndex !== -1) {
						this.itemIndex = foundIndex
						this.groupedItemsIndex = Number(i)
						return
					}
				}
			} else {
				const foundIndex = this.itemsArray.findIndex(({ itemId }) => itemId === id)
				this.itemIndex = foundIndex
			}
		},
		saveToList() {
			this.$refs.modal.openModal()
		},
		handleAddToListsComplete() {
			this.$refs.modal.closeModal()
		},
		handleHideParent() {
			this.$refs.modal.dynamicHide()
			this.$refs.modal.enableIsNested()
		},
		handleShowParent() {
			this.$refs.modal.openModal()
			this.$refs.modal.dynamicShow()
			this.$refs.modal.disableIsNested()
		},
		resetGallery() {
			this.currentSlide = 0
			this.$nextTick(() => {
				this.gallerySlider?.refresh()
				this.gallerySlider?.moveToSlide(0, 0)
			})
		},
		handleSelectGroupedItems(index) {
			this.itemIndex = 0
			this.groupedItemsIndex = index
			if (this.activeInfoTab > this.productInfoFields.length - 1) {
				this.handleActiveInfoTab(this.productInfoFields.length - 1 || 0)
			}
			this.resetGallery()
		},
		handleSelectItem(item, groupIndex = 0) {
			if (this.isDemiColour) {
				this.setActiveGroupVariant({ item })
				this.variationDropdownIsActive = false
			}
			this.itemIndex = this.itemsArray.findIndex((i) => {
				return i.itemId === item.itemId
			})

			if (this.activeInfoTab > this.productInfoFields.length - 1) {
				this.handleActiveInfoTab(this.productInfoFields.length - 1 || 0)
			}
			this.resetGallery()

			this.handleOgUpdate(this.currentItem)
		},
		handleActiveInfoTab(index) {
			this.activeInfoTab = index
		},
		handleOgUpdate(item) {
			if (item) {
				const descriptionArr = item.description.replace(/<[^>]*>?/gm, '').split(/\r?\n/)
				const description = descriptionArr.length > 1 ? descriptionArr[1] : descriptionArr[0]
				document.querySelector('meta[property="og:title"]').setAttribute('content', item.title.replace('- ', ''))
				document
					.querySelector('meta[property="og:description"]')
					.setAttribute('content', `${description.substr(0, 197)}...`)
				document.querySelector('meta[property="og:image"]').setAttribute('content', item.productImage)
			}
		},
		hasLightHexValue(item) {
			if (!item.hex) return false
			return isBright(item.hex)
		},
		hexValue(item) {
			return item.hex ? item.hex : null
		},
		swatchBgImgUrl(item) {
			return item.hasSwatchImage ? `url('${this.$assetBucketBaseUrl}/products/${item.itemCode}-swatch.jpg')` : null
		},
		formatForMarkup(string) {
			return RegExp(/<\/?[a-z][\s\S]*>/i).test(string) ? string : `<p>${string}</p>`
		},
		showColourWheel() {
			Swal.fire({
				showCloseButton: true,
				allowOutsideClick: true,
				allowEscapeKey: true,
				title: '',
				html: `<figure role="figure" aria-label="${this.$assetBucketBaseUrl}"><img src="${this.$assetBucketBaseUrl}/products/demi-colour-wheel.png" alt="Corrector Wheel" /></figure>`,
				showConfirmButton: false,
				showCancelButton: false,
				customClass: {
					container: 'is-image',
				},
			})
		},
		showShippingModal() {
			Swal.fire({
				...shippingModal(this.countryCode),
			})
		},
		showSizeGuide(isBottoms) {
			Swal.fire({
				...sizeGuide(isBottoms),
			})
		},
		handleSelectCollectionLevel(item) {
			this.showCollectionLevelsModal = false
			if (item && item.itemId !== this.currentItem.itemId) {
				this.handleSelectItem(item)
				this.$router.replace({
					name: this.routeName,
					params: {
						languageCode: this.languageCode,
						categoryId: this.category.WebCategoryID,
						categorySlug: this.category.slug,
						itemId: item.itemId,
						itemSlug: item.itemRaw.slug,
					},
				})
			}
		},
		showReturnPolicy() {
			Swal.fire({ ...shortReturnPolicy() })
		},
		handleGalleryMinHeight() {
			const productGallery = document.getElementById('product-gallery')
			productGallery.style.minHeight = `${productGallery.offsetHeight}px`
		},
	},
	watch: {
		shouldRenderGalleryAsSlider: {
			immediate: true,
			handler(value) {
				if (value) {
					if (!this.gallerySlider) {
						this.$nextTick(() => {
							this.gallerySlider = new KeenSlider(this.$refs.gallerySliderRef, {
								initial: this.currentSlide,
								slideChanged: (slider) => {
									this.currentSlide = slider.details().relativeSlide
								},
							})
						})
					}
				} else {
					this.$nextTick(() => {
						this.gallerySlider?.destroy()
						this.gallerySlider = null
					})
				}
			},
		},

		currentItemId: {
			immediate: true,
			handler: debounce(function () {
				this.handleGalleryMinHeight()
				const itemCode = this.currentItem && this.currentItem.itemCode ? this.currentItem.itemCode.toString() : null
				if (itemCode) {
					const payload = {
						detail: {
							eventType: 'productBrowse',
							data: {
								itemCode: itemCode,
							},
						},
					}
					const trackingEvent = new CustomEvent('tracking', payload)
					window.dispatchEvent(trackingEvent)
				}
			}, 1000),
		},
	},
}
</script>
<style lang="scss">
.label-text {
	color: #9e3501 !important;
}
.fine-mist-perfume {
	.variant-swatches--container {
		grid-template-columns: repeat(auto-fit, minmax(175px, 1fr)) !important;
		@media only screen and (min-width: 1023px) {
			grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)) !important;
		}
		[class*='red-apple-raspberry-blossom'] {
			word-break: break-all;
			max-width: 155px;
		}
	}
}
button.btn-save-to-list {
	margin: 0 auto 24px auto;
	display: flex;
	padding: 8px 0px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	svg {
		fill: none;
		stroke: #000000;
		width: 24px;
		height: 24px;
	}
	color: #000000;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}
</style>
